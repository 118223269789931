'use strict';

import { ajax } from '../../_scripts/commons/ajax'

export default class Autocomplete {
  constructor() {
    (function($) {
      $.extend({
        bookmark: {
          init: $obj => {
            const bookmarkConfig = $obj.data('bookmark');

            $obj.data('has-bookmark', true);

            $obj.attr('data-has-bookmark', true);

            let $toolbar = $obj.find('[data-toolbar]');

            if (!$toolbar.length) {
              $toolbar = $('<div>', {
                'data-toolbar': true
              });

              if ($obj.css('position') === 'static') {
                $obj.css('position', 'relative');
              }

              $obj.append($toolbar);
            }

            $toolbar[bookmarkConfig.mode || 'append']($('<div>', {
              class: 'tooblar-item bookmark-toggler' + (bookmarkConfig.active ? ' active' : ''),
              'data-toggle': 'bookmark'
            }).html($('<i>', {
              class: 'material-icons bookmark-on'
            }).html('star')).append($('<i>', {
              class: 'material-icons bookmark-off'
            }).html('star_border')));
          },
          toggle: $obj => {
            const $bookmarkContainer = $obj.closest('[data-bookmark]');

            const bookmarkConfig = $bookmarkContainer.data('bookmark');

            const $bookmarksGroup = $obj.closest('[data-bookmarks-group]');

            if ($bookmarksGroup.length && $obj.hasClass('active')) {
              return;
            }

            $.bookmark[$obj.hasClass('active') ? 'remove' : 'add']($obj);
          },
          add: $obj => {
            const $bookmarkContainer = $obj.closest('[data-bookmark]');

            const bookmarkConfig = $bookmarkContainer.data('bookmark');

            const $bookmarksGroup = $obj.closest('[data-bookmarks-group]');

            ajax.run({
              url: bookmarkConfig.urls.add
            }).then(json => {
              if (json.status === 'success') {
                if ($bookmarksGroup.length) {
                  $bookmarksGroup.find('[data-toggle="bookmark"]').removeClass('active');
                }

                $obj.addClass('active');
              }
            }).catch(err => {
              console.log(err);
            });
          },
          remove: $obj => {
            const $bookmarkContainer = $obj.closest('[data-bookmark]');

            const bookmarkConfig = $bookmarkContainer.data('bookmark');

            ajax.run({
              url: bookmarkConfig.urls.remove
            }).then(json => {
              if (json.status === 'success') {
                $obj.removeClass('active');
              }
            }).catch(err => {
              console.log(err);
            });
          }
        }
      });
    })($);

    const initBookmark = () => {
      $('[data-bookmark]:not([data-has-bookmark])').each(function() {
        $.bookmark.init($(this));
      });
    };

    initBookmark();

    $(document).on('handlebars.render.end', function() {
      initBookmark();
    });

    $('body').on('click', '[data-toggle="bookmark"]', function(e) {
      e.preventDefault();

      $.bookmark.toggle($(this));
    });
  }
}
