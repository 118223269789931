'use strict';

import debounce from 'lodash/debounce';

export default class Table {
  constructor() {
    $('body').on('keyup', '[data-table-search]', debounce(function () {
      const valueToSearch = new RegExp($(this).val(), 'ig');

      const $target = $($(this).data('table-search'));

      $target.find('tbody tr').each(function() {
        const currentText = $(this).text().trim();

        $(this).removeClass('d-none');

        if (!valueToSearch.test(currentText)) {
          $(this).addClass('d-none');
        }
      });
    }, 300));
  }
}
